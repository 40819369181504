var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-bg-white tw-rounded-md tw-border-b-8 tw-pb-11 tw-border-b-solid tw-border-grey-two"},[_c('div',{staticClass:"tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-6 tw-pt-6 tw-px-10"},[_c('div',{staticClass:"tw-flex tw-justify-start tw-items-center"},[_c('UserAvatar',{staticClass:"tw-mr-4",attrs:{"user":_vm.activity.user,"size":"56"}}),_c('div',{staticClass:"tw-text-charcoal tw-text-left"},[_c('div',{staticClass:"tw-text-base tw-font-semibold"},[_vm._v(" "+_vm._s(_vm.activity.user.firstName)+" "+_vm._s(_vm.activity.user.lastName)+" ")]),_c('div',{staticClass:"tw-text-xs"},[_vm._v(" "+_vm._s(_vm.getFormattedDate(_vm.activity.createdAt))+" ")])])],1),(_vm.isTripOwner || _vm.isPostOwner)?_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({staticClass:"tw-ml-auto tw-self-center tw-text-grey-one",attrs:{"size":"36"}},on),[_vm._v("mdi-dots-vertical")])]}}],null,false,2144023249)},[_c('v-list',{staticClass:"tw-text-xs"},[_c('v-list-item',{staticClass:"tw-cursor-pointer"},[_c('router-link',{staticClass:"tw-text-charcoal",attrs:{"to":{
              name: 'EditActivityRoute',
              params: { id: _vm.activity.tripId, uuid: _vm.activity.uuid }
            }}},[_c('v-icon',{staticClass:"tw-mr-2",attrs:{"size":"16","color":"#333333"}},[_vm._v("mdi-pencil")]),_vm._v(" Edit")],1)],1),_c('v-list-item',{staticClass:"tw-cursor-pointer",on:{"click":function($event){return _vm.$emit('delete', _vm.activity.uuid)}}},[_c('v-icon',{staticClass:"tw-mr-2",attrs:{"size":"16","color":"#333333"}},[_vm._v("mdi-delete")]),_vm._v("Delete ")],1),(_vm.activity.linkUrl)?_c('v-list-item',{staticClass:"tw-cursor-pointer",on:{"click":_vm.copyLink}},[_c('v-icon',{staticClass:"tw-mr-2",attrs:{"size":"16","color":"#333333"}},[_vm._v("mdi-share-variant")]),_vm._v("Share")],1):_vm._e()],1)],1):_c('div',{staticClass:"tw-ml-auto tw-cursor-pointer"},[(_vm.activity.linkUrl)?_c('v-icon',{staticClass:"tw-text-grey-one",on:{"click":_vm.copyLink}},[_vm._v("mdi-share-variant")]):_vm._e()],1)],1),_c('div',{staticClass:"tw-text-left"},[_c('p',{staticClass:"tw-text-charcoal tw-font-normal tw-px-10"},[_vm._v(" "+_vm._s(_vm.activity.text)+" ")]),_c('div',{staticClass:"tw-flex tw-flex-col"},[_c('div',{staticClass:"tw-h-200 tw-bg-cover tw-bg-center",style:({
          backgroundImage: _vm.activity.imageUrl
            ? 'url(' + _vm.activity.imageUrl + ')'
            : 'url(' + _vm.placeholderImageUrl(_vm.activity.type) + ')'
        })}),_c('div',{staticClass:"tw-py-6 tw-px-10"},[_c('p',{staticClass:"tw-text-left tw-font-figtree tw-font-semibold tw-text-charcoal tw-mb-1 tw-w-11/12 tw-truncate"},[_vm._v(" "+_vm._s(_vm.activity.name)+" ")]),_c('p',{staticClass:"tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey tw-text-xs tw-mb-1"},[_vm._v(" "+_vm._s(_vm.activity.nameOfPlace)+" ")]),(!_vm.showFullNote)?_c('p',{staticClass:"tw-text-left tw-font-figtree tw-font-normal tw-text-charcoal tw-text-xs tw-mb-1"},[_vm._v(" "+_vm._s(_vm.truncatedNote)+" "),(this.activity.note.length > this.noteMaxLength)?_c('button',{staticClass:"tw-underline tw-text-light-grey",on:{"click":function($event){_vm.showFullNote = true}}},[_vm._v(" see more ")]):_vm._e()]):_c('p',{staticClass:"tw-text-left tw-font-figtree tw-font-normal tw-text-charcoal tw-text-xs tw-mb-1"},[_vm._v(" "+_vm._s(_vm.activity.note)+" "),(this.activity.note.length > this.noteMaxLength)?_c('button',{staticClass:"tw-underline tw-text-light-grey",on:{"click":function($event){_vm.showFullNote = false}}},[_vm._v(" see less ")]):_vm._e()]),(_vm.activity.linkUrl)?_c('a',{staticClass:"tw-underline tw-text-xs tw-text-link-blue tw-cursor-pointer",attrs:{"href":_vm.getHrefLink(_vm.activity.linkUrl),"target":"_blank","rel":"noopener noreferrer"}},[_vm._v("View details")]):_vm._e()])])]),(_vm.addToItinerary && (_vm.isTripOwner || _vm.isPostOwner))?_c('div',{staticClass:"tw-pt-4 tw-pb-10 tw-px-10"},[_c('v-btn',{staticClass:"tw-tracking-normal",attrs:{"block":"","rounded":"","depressed":"","x-large":"","color":"primary","to":{
        name: 'ActivityToItineraryRoute',
        params: { id: _vm.activity.tripId, uuid: _vm.activity.uuid }
      }}},[_vm._v(" Add to Itinerary ")])],1):_vm._e(),(!_vm.addToItinerary)?_c('div',{staticClass:"tw-py-4 tw-px-10 tw-pb-10"},[_c('router-link',{staticClass:"tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block",attrs:{"to":{
        name: 'TripItineraryList',
        params: { id: this.$route.params.id, activityUUID: _vm.activity.uuid }
      }}},[_vm._v(" View Event on Itinerary ")])],1):_vm._e(),_c('div',{staticClass:"tw-border-t tw-border-b tw-border-solid tw-border-lightest-grey tw-py-3 tw-mx-10 tw-flex tw-flex-row tw-justify-between tw-text-xxs tw-text-charcoal"},[_c('button',{staticClass:"j-reaction-pill",class:{
        'tw-bg-chartreuse': _vm.checkIfUserReacted(_vm.activity, 'UP'),
        'tw-bg-lightest-grey':
          (!_vm.checkIfUserReacted(_vm.activity, 'UP') &&
            _vm.checkIfUserReacted(_vm.activity)) ||
          !_vm.checkIfUserReacted(_vm.activity)
      },attrs:{"disabled":_vm.reactionButtonDisabled},on:{"click":function($event){return _vm.postReaction('UP', _vm.activity)}}},[(_vm.upCount > 0)?_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(this.upCount))]):_vm._e(),_vm._v(" My fave "),(_vm.checkIfUserReacted(_vm.activity, 'UP'))?_c('span',[_vm._v("❤️")]):_c('span',[_vm._v("💛")])]),_c('button',{staticClass:"j-reaction-pill",class:{
        'tw-bg-dark-green tw-text-white': _vm.checkIfUserReacted(
          _vm.activity,
          'NEUTRAL'
        ),
        'tw-bg-lightest-grey':
          (!_vm.checkIfUserReacted(_vm.activity, 'NEUTRAL') &&
            _vm.checkIfUserReacted(_vm.activity)) ||
          !_vm.checkIfUserReacted(_vm.activity)
      },attrs:{"disabled":_vm.reactionButtonDisabled},on:{"click":function($event){return _vm.postReaction('NEUTRAL', _vm.activity)}}},[(_vm.neutralCount > 0)?_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(this.neutralCount))]):_vm._e(),_vm._v(" I'm down 👍 ")]),_c('button',{staticClass:"j-reaction-pill",class:{
        'tw-bg-dark-green tw-text-white': _vm.checkIfUserReacted(
          _vm.activity,
          'DOWN'
        ),
        'tw-bg-lightest-grey':
          (!_vm.checkIfUserReacted(_vm.activity, 'DOWN') &&
            _vm.checkIfUserReacted(_vm.activity)) ||
          !_vm.checkIfUserReacted(_vm.activity)
      },attrs:{"disabled":_vm.reactionButtonDisabled},on:{"click":function($event){return _vm.postReaction('DOWN', _vm.activity)}}},[(_vm.downCount > 0)?_c('span',{staticClass:"tw-font-bold"},[_vm._v(_vm._s(this.downCount))]):_vm._e(),_vm._v(" Ehhh 😅 ")])]),_c('div',{staticClass:"tw-py-3 tw-flex tw-flex-row tw-justify-end tw-text-xxs tw-text-charcoal tw-px-10"},[_c('span',{staticClass:"tw-cursor-pointer tw-underline",on:{"click":_vm.toggleDisplayAllComments}},[_vm._v(_vm._s(_vm.activity.post_comments.length)+" comments")])]),_c('div',{staticClass:"tw-px-10"},[_vm._l((_vm.displayedComments),function(comment){return _c('Comment',{key:comment.id,attrs:{"comment":comment,"userId":_vm.user.id},on:{"deleted":_vm.deletedComment}})}),_c('CommentBuilder',{attrs:{"user":_vm.user,"item":_vm.activity},on:{"comment-posted":_vm.updateActivity}})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div
    class="tw-bg-white tw-rounded-md tw-border-b-8 tw-pb-11 tw-border-b-solid tw-border-grey-two"
  >
    <div
      class="tw-flex tw-flex-row tw-items-center tw-justify-between tw-mb-6 tw-pt-6 tw-px-10"
    >
      <div class="tw-flex tw-justify-start tw-items-center">
        <UserAvatar :user="activity.user" size="56" class="tw-mr-4" />
        <div class="tw-text-charcoal tw-text-left">
          <div class="tw-text-base tw-font-semibold">
            {{ activity.user.firstName }} {{ activity.user.lastName }}
          </div>
          <div class="tw-text-xs">
            {{ getFormattedDate(activity.createdAt) }}
          </div>
        </div>
      </div>

      <v-menu bottom left v-if="isTripOwner || isPostOwner">
        <template v-slot:activator="{ on }">
          <v-icon
            class="tw-ml-auto tw-self-center tw-text-grey-one"
            v-on="on"
            size="36"
            >mdi-dots-vertical</v-icon
          >
        </template>
        <v-list class="tw-text-xs">
          <v-list-item class="tw-cursor-pointer">
            <router-link
              class="tw-text-charcoal"
              :to="{
                name: 'EditActivityRoute',
                params: { id: activity.tripId, uuid: activity.uuid }
              }"
            >
              <v-icon size="16" class="tw-mr-2" color="#333333"
                >mdi-pencil</v-icon
              >
              Edit</router-link
            >
          </v-list-item>
          <v-list-item
            class="tw-cursor-pointer"
            @click="$emit('delete', activity.uuid)"
          >
            <v-icon size="16" class="tw-mr-2" color="#333333">mdi-delete</v-icon
            >Delete
          </v-list-item>
          <v-list-item
            v-if="activity.linkUrl"
            class="tw-cursor-pointer"
            @click="copyLink"
          >
            <v-icon size="16" class="tw-mr-2" color="#333333"
              >mdi-share-variant</v-icon
            >Share</v-list-item
          >
        </v-list>
      </v-menu>
      <div v-else class="tw-ml-auto tw-cursor-pointer">
        <v-icon
          class="tw-text-grey-one"
          v-if="activity.linkUrl"
          @click="copyLink"
          >mdi-share-variant</v-icon
        >
      </div>
    </div>
    <div class="tw-text-left">
      <p class="tw-text-charcoal tw-font-normal tw-px-10">
        {{ activity.text }}
      </p>
      <div class="tw-flex tw-flex-col">
        <div
          :style="{
            backgroundImage: activity.imageUrl
              ? 'url(' + activity.imageUrl + ')'
              : 'url(' + placeholderImageUrl(activity.type) + ')'
          }"
          class="tw-h-200 tw-bg-cover tw-bg-center"
        ></div>
        <div class="tw-py-6 tw-px-10">
          <p
            class="tw-text-left tw-font-figtree tw-font-semibold tw-text-charcoal tw-mb-1 tw-w-11/12 tw-truncate"
          >
            {{ activity.name }}
          </p>
          <p
            class="tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey tw-text-xs tw-mb-1"
          >
            {{ activity.nameOfPlace }}
          </p>
          <p
            class="tw-text-left tw-font-figtree tw-font-normal tw-text-charcoal tw-text-xs tw-mb-1"
            v-if="!showFullNote"
          >
            {{ truncatedNote }}
            <button
              v-if="this.activity.note.length > this.noteMaxLength"
              @click="showFullNote = true"
              class="tw-underline tw-text-light-grey"
            >
              see more
            </button>
          </p>
          <p
            class="tw-text-left tw-font-figtree tw-font-normal tw-text-charcoal tw-text-xs tw-mb-1"
            v-else
          >
            {{ activity.note }}
            <button
              v-if="this.activity.note.length > this.noteMaxLength"
              @click="showFullNote = false"
              class="tw-underline tw-text-light-grey"
            >
              see less
            </button>
          </p>
          <a
            class="tw-underline tw-text-xs tw-text-link-blue tw-cursor-pointer"
            :href="getHrefLink(activity.linkUrl)"
            target="_blank"
            rel="noopener noreferrer"
            v-if="activity.linkUrl"
            >View details</a
          >
        </div>
      </div>
    </div>
    <div
      class="tw-pt-4 tw-pb-10 tw-px-10"
      v-if="addToItinerary && (isTripOwner || isPostOwner)"
    >
      <v-btn
        block
        rounded
        depressed
        x-large
        color="primary"
        class="tw-tracking-normal"
        :to="{
          name: 'ActivityToItineraryRoute',
          params: { id: activity.tripId, uuid: activity.uuid }
        }"
      >
        Add to Itinerary
      </v-btn>
    </div>
    <div class="tw-py-4 tw-px-10 tw-pb-10" v-if="!addToItinerary">
      <router-link
        class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block"
        :to="{
          name: 'TripItineraryList',
          params: { id: this.$route.params.id, activityUUID: activity.uuid }
        }"
      >
        View Event on Itinerary
      </router-link>
    </div>
    <div
      class="tw-border-t tw-border-b tw-border-solid tw-border-lightest-grey tw-py-3 tw-mx-10 tw-flex tw-flex-row tw-justify-between tw-text-xxs tw-text-charcoal"
    >
      <button
        :disabled="reactionButtonDisabled"
        class="j-reaction-pill"
        @click="postReaction('UP', activity)"
        :class="{
          'tw-bg-chartreuse': checkIfUserReacted(activity, 'UP'),
          'tw-bg-lightest-grey':
            (!checkIfUserReacted(activity, 'UP') &&
              checkIfUserReacted(activity)) ||
            !checkIfUserReacted(activity)
        }"
      >
        <span v-if="upCount > 0" class="tw-font-bold">{{ this.upCount }}</span>
        My fave <span v-if="checkIfUserReacted(activity, 'UP')">❤️</span
        ><span v-else>💛</span>
      </button>
      <button
        :disabled="reactionButtonDisabled"
        class="j-reaction-pill"
        @click="postReaction('NEUTRAL', activity)"
        :class="{
          'tw-bg-dark-green tw-text-white': checkIfUserReacted(
            activity,
            'NEUTRAL'
          ),
          'tw-bg-lightest-grey':
            (!checkIfUserReacted(activity, 'NEUTRAL') &&
              checkIfUserReacted(activity)) ||
            !checkIfUserReacted(activity)
        }"
      >
        <span v-if="neutralCount > 0" class="tw-font-bold">{{
          this.neutralCount
        }}</span>
        I'm down 👍
      </button>
      <button
        :disabled="reactionButtonDisabled"
        class="j-reaction-pill"
        @click="postReaction('DOWN', activity)"
        :class="{
          'tw-bg-dark-green tw-text-white': checkIfUserReacted(
            activity,
            'DOWN'
          ),
          'tw-bg-lightest-grey':
            (!checkIfUserReacted(activity, 'DOWN') &&
              checkIfUserReacted(activity)) ||
            !checkIfUserReacted(activity)
        }"
      >
        <span v-if="downCount > 0" class="tw-font-bold">{{
          this.downCount
        }}</span>
        Ehhh 😅
      </button>
    </div>
    <div
      class="tw-py-3 tw-flex tw-flex-row tw-justify-end tw-text-xxs tw-text-charcoal tw-px-10"
    >
      <span
        class="tw-cursor-pointer tw-underline"
        @click="toggleDisplayAllComments"
        >{{ activity.post_comments.length }} comments</span
      >
    </div>
    <div class="tw-px-10">
      <Comment
        v-for="comment in displayedComments"
        :key="comment.id"
        :comment="comment"
        :userId="user.id"
        @deleted="deletedComment"
      />
      <CommentBuilder
        :user="user"
        :item="activity"
        @comment-posted="updateActivity"
      />
    </div>
  </div>
</template>
<script>
import UserAvatar from "@/components/shared/UserAvatar.vue";
import Comment from "@/components/accommodation/Comment.vue";
import CommentBuilder from "@/components/shared/CommentBuilder.vue";
import { FormattedDateRange } from "@/mixins/FormattedDateRange.js";
import { UrlHelpers } from "@/mixins/UrlHelpers.js";
import { mapActions } from "vuex";

export default {
  components: {
    UserAvatar,
    Comment,
    CommentBuilder
  },
  mixins: [FormattedDateRange, UrlHelpers],
  data() {
    return {
      comment: {
        postUUID: this.activity.uuid,
        tripId: this.activity.tripId,
        authorId: this.user.id,
        text: ""
      },
      reaction: {
        postUUID: this.activity.uuid,
        authorId: this.user.id,
        type: ""
      },
      editedComment: null,
      sendButtonVisible: false,
      displayAllComments: false,
      reactionButtonDisabled: false,
      showFullNote: false,
      truncatedNote: "",
      noteMaxLength: 100
    };
  },
  props: {
    activity: {
      type: Object
    },
    user: {
      type: Object
    },
    isTripOwner: {
      type: Boolean
    },
    addToItinerary: {
      type: Boolean
    }
  },
  computed: {
    upCount() {
      return this.getCountByType("UP");
    },
    neutralCount() {
      return this.getCountByType("NEUTRAL");
    },
    downCount() {
      return this.getCountByType("DOWN");
    },
    displayedComments() {
      if (this.displayAllComments) {
        return this.activity.post_comments;
      } else {
        // Sort comments by createdAt in descending order
        const sortedComments = [...this.activity.post_comments].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        // Take the top two comments
        return sortedComments.slice(0, 2);
      }
    },
    isPostOwner() {
      return this.activity.authorId === this.user.id;
    },
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    }
  },
  methods: {
    ...mapActions({
      getActivity: "post-activity/find"
    }),
    placeholderImageUrl(type) {
      return require(`@/assets/svgs/activities-placeholders/${type}.png`);
    },
    async updateActivity() {
      this.editedComment = null;
      const updatedActivity = await this.getActivity({
        query: {
          uuid: this.activity.uuid
        }
      });
      this.$emit("activity-updated", updatedActivity.data[0]);
    },
    toggleDisplayAllComments() {
      this.displayAllComments = !this.displayAllComments;
    },
    async postReaction(value, activity) {
      const userId = this.user.id;
      const userReacted = activity.post_likes.some(
        (obj) => obj.authorId === userId
      );
      const sameReaction = activity.post_likes.some(
        (obj) => obj.authorId === userId && obj.type === value
      );

      try {
        if (sameReaction) {
          await this.removeExistingReaction(activity, userId, value);
          return;
        }

        if (userReacted) {
          await this.removePreviousReaction(activity, userId);
        }

        await this.createOrUpdateReaction(activity, value);
      } catch (error) {
        console.error("Error posting a reaction:", error);
      }
    },
    async removeExistingReaction(activity, userId, value) {
      this.reactionButtonDisabled = true;
      const existingReaction = activity.post_likes.find(
        (obj) => obj.authorId === userId && obj.type === value
      );
      if (existingReaction) {
        await this.$store.dispatch("post-like/remove", existingReaction.id);
        this.$delete(
          activity.post_likes,
          activity.post_likes.indexOf(existingReaction)
        );
      }
      this.reactionButtonDisabled = false;
    },
    async removePreviousReaction(activity, userId) {
      this.reactionButtonDisabled = true;
      const previousReaction = activity.post_likes.find(
        (obj) => obj.authorId === userId
      );
      if (previousReaction) {
        await this.$store.dispatch("post-like/remove", previousReaction.id);
        this.$delete(
          activity.post_likes,
          activity.post_likes.indexOf(previousReaction)
        );
      }
      this.reactionButtonDisabled = false;
    },
    async createOrUpdateReaction(activity, value) {
      this.reactionButtonDisabled = true;
      this.reaction.type = value;
      const like = await this.$store.dispatch(
        "post-like/create",
        this.reaction
      );
      this.$set(activity.post_likes, activity.post_likes.length, like);
      this.reactionButtonDisabled = false;
    },
    checkIfUserReacted(activity, reaction) {
      // Check if the user has reacted to the specified activity with a specific type
      if (reaction) {
        return activity.post_likes.some(
          (obj) => obj.authorId === this.user.id && obj.type === reaction
        );
      } else {
        // Check if the user has reacted with any type for the specified activity
        return activity.post_likes.some((obj) => obj.authorId === this.user.id);
      }
    },
    getCountByType(type) {
      return this.activity.post_likes.filter((obj) => obj.type === type).length;
    },
    toggleSendButtonOn() {
      this.sendButtonVisible = true;
    },
    toggleSendButtonOff() {
      if (this.comment.text === "") this.sendButtonVisible = false;
    },
    truncateNote() {
      if (this.activity.note.length > this.noteMaxLength) {
        this.truncatedNote =
          this.activity.note.substring(0, this.noteMaxLength) + "...";
      } else {
        this.truncatedNote = this.activity.note;
      }
    },
    copyLink() {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(this.activity.linkUrl);
      }
      this.$store.dispatch("meta/showGlobalAlert", {
        type: "success",
        text: "Link copied!",
        timeout: 2000
      });
    },
    deletedComment(comment) {
      this.activity.post_comments = this.activity.post_comments.filter(
        (c) => c.id !== comment.id
      );
    }
  },
  mounted() {
    this.truncateNote();
  }
};
</script>
<style lang="scss">
.j-reaction-pill {
  @apply tw-rounded-3xl tw-py-2 tw-px-5;
}
</style>

<template>
  <v-container :style="{ padding: '0', marginTop: '12px' }">
    <v-row v-if="this.activities.length > 0">
      <v-col cols="12" :style="padding">
        <h2
          class="tw-text-left tw-font-figtree tw-text-22 tw-font-semibold tw-leading-30 tw-text-dark-green"
        >
          Suggest an Activity
        </h2>
        <p
          class="tw-text-left tw-font-figtree tw-font-normal tw-text-light-grey tw-mb-8"
        >
          View the crew’s suggestions below & give feedback or post another
          activity.
        </p>
        <v-btn
          x-large
          rounded
          block
          depressed
          class="j-btn tw-bg-dark-green tw-mt-4 tw-tracking-normal tw-text-white tw-mb-10"
          :to="{
            name: 'SuggestActivityRoute',
            params: { tripId: this.$route.params.id }
          }"
          ><v-icon>mdi-plus</v-icon> Suggest Things To Do
        </v-btn>
      </v-col>
      <v-col cols="12" :style="padding">
        <p
          class="tw-text-18 tw-mt-6 tw-mb-0 tw-text-left tw-text-dark-green tw-font-bold"
        >
          Explore Activities to Book
        </p>
        <p class="tw-text-left tw-text-xs tw-text-light-grey tw-mb-6">
          We earn a small amount when you buy through our links.
        </p>
        <div class="tw-w-full tw-flex tw-mb-10">
          <a
            href="https://www.viator.com/?pid=P00161876&mcid=42383&medium=link&campaign=app-things-to-do"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-1/2 tw-mr-2"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Viator</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a>
          <a
            href="https://www.anrdoezrs.net/click-101084685-15002975"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-1/2 tw-ml-2"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Get Your Guide</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a>
        </div>
      </v-col>
      <v-col
        cols="12"
        :style="padding"
        class="tw-text-left tw-flex tw-flex-col tw-items-center tw-justify-between tw-pb-4 tw-border-b-8 tw-border-b-solid tw-border-grey-two"
        v-if="activities && activities.length"
      >
        <div
          class="tw-flex tw-w-full tw-items-center tw-justify-between tw-pb-4"
        >
          <span class="tw-text-18 tw-text-dark-green tw-font-bold"
            >Crew Suggestions</span
          >
          <v-menu
            offset-y
            bottom
            left
            v-if="activities && activities.length > 1"
          >
            <template v-slot:activator="{ on }">
              <div
                class="tw-flex tw-flex-row tw-items-center tw-justify-start"
                v-on="on"
              >
                <span
                  class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block tw-text-xxs tw-mr-2 tw-cursor-pointer"
                  >SORT BY</span
                >
                <img src="@/assets/svgs/chevron.svg" alt="Chevron" />
              </div>
            </template>
            <v-list class="tw-text-xs tw-text-charcoal">
              <v-list-item
                class="tw-cursor-pointer"
                @click="fetchAndSortActivities('createdAt')"
              >
                Most Recent</v-list-item
              >
              <v-list-item
                class="tw-cursor-pointer"
                @click="fetchAndSortByLikes"
                >Top Posts</v-list-item
              >
            </v-list>
          </v-menu>
        </div>
        <div class="tw-w-110">
          <j-chip-group
            :items="activityTypes"
            v-bind:multiple="true"
            @input="filterType = $event"
          />
        </div>
      </v-col>
      <v-col
        cols="12"
        v-if="filteredActivities && filteredActivities.length > 0"
        :style="{ padding: '0' }"
      >
        <Post
          v-for="activity in filteredActivities"
          :key="activity.uuid"
          :activity="activity"
          :user="user"
          :addToItinerary="checkAddToItinerary(activity)"
          @activity-updated="updateActivity($event)"
          @delete="toggleDialog($event)"
          :is-trip-owner="isTripOwner"
        />
      </v-col>
      <v-col cols="12" class="tw-py-4" v-else :style="{ padding: '0' }">
        <p class="tw-mt-4 tw-text-dark-green">No Activities in this category</p>
      </v-col>
    </v-row>
    <v-row v-else class="tw-mt-3">
      <v-col class="text-center" cols="12" :style="padding">
        <img
          src="@/assets/svgs/disco-ball.svg"
          alt="Disco Ball"
          class="tw-rounded-md tw-block tw-mx-auto"
        />
        <h1 class="tw-mt-4 tw-mb-2 tw-text-lg tw-font-semibold">
          Let's have fun
        </h1>
        <p
          class="tw-text-center tw-font-figtree tw-font-normal tw-text-light-grey tw-mb-10"
        >
          Suggest things to do, places to see, <br />
          and where to eat.
        </p>
        <v-btn
          x-large
          rounded
          block
          depressed
          class="j-btn tw-bg-dark-green tw-mt-4 tw-tracking-normal tw-text-white"
          :to="{
            name: 'SuggestActivityRoute',
            params: { tripId: this.$route.params.id }
          }"
          ><v-icon>mdi-plus</v-icon> Suggest Things To Do
        </v-btn>
      </v-col>
      <v-col cols="12" :style="padding">
        <p
          class="tw-text-18 tw-mt-16 tw-mb-0 tw-text-left tw-text-dark-green tw-font-bold"
        >
          Explore Activities to Book
        </p>
        <p class="tw-text-left tw-text-xs tw-text-light-grey tw-mb-6">
          We earn a small amount when you buy through our links.
        </p>
        <div class="tw-w-full tw-flex tw-mb-10">
          <a
            href="https://www.viator.com/?pid=P00161876&mcid=42383&medium=link&campaign=app-things-to-do"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-1/2 tw-mr-2"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Viator</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a>
          <a
            href="https://www.anrdoezrs.net/click-101084685-15002975"
            target="_blank"
            rel="noopener noreferrer"
            class="tw-w-1/2 tw-ml-2"
          >
            <button
              class="tw-flex tw-items-center tw-justify-between tw-shadow-custom tw-rounded-xl tw-p-4 tw-w-full"
              type="button"
            >
              <span class="tw-text-left tw-text-dark-green tw-font-bold"
                >Get Your Guide</span
              >
              <img
                class="tw-rounded-full"
                src="@/assets/svgs/arrow-right-dark-background.svg"
                alt="Go"
              />
            </button>
          </a>
        </div>
      </v-col>
    </v-row>
    <DeleteActivityDialog
      :is-visible="isVisible"
      :uuid="uuidToDelete"
      @cancel="toggleDialog"
      @close="toggleDialog"
    />
  </v-container>
</template>
<script>
import { ACTIVITY_TYPES } from "@/enums/activities.js";
import Post from "@/components/activities/Post.vue";
import DeleteActivityDialog from "@/components/activities/DeleteActivityDialog.vue";
export default {
  name: "ActivitiesList",
  components: { Post, DeleteActivityDialog },
  data() {
    return {
      activities: [],
      itinerary: [],
      activityTypes: ACTIVITY_TYPES,
      uuidToDelete: null,
      isVisible: false,
      filterType: null,
      padding: {
        paddingLeft: "40px",
        paddingRight: "40px",
        paddingTop: "0",
        paddingBottom: "0"
      }
    };
  },
  computed: {
    trip() {
      return this.$store.state.trip.keyedById[this.$route.params.id];
    },
    user() {
      return this.$store.state.auth.user;
    },
    isTripOwner() {
      if (this.trip) return this.trip.ownerId === this.user.id;
      else return null;
    },
    filteredActivities() {
      if (this.filterType && this.filterType.length > 0) {
        return this.activities.filter((activity) =>
          this.filterType.includes(activity.type)
        );
      }
      return this.activities;
    }
  },
  methods: {
    checkAddToItinerary(activity) {
      let activityFound = this.itinerary.find(
        (item) => item.uuid === activity.uuid
      );
      if (activityFound) return false;
      return true;
    },
    toggleDialog(uuid) {
      this.uuidToDelete = uuid || null;
      this.isVisible = !this.isVisible;
      if (!uuid) this.fetchActivities();
    },
    async fetchTrip() {
      await this.$store.dispatch("trip/get", [
        this.$route.params.id,
        {
          query: {
            include: "destination,users,survey,trip_invitation"
          }
        }
      ]);
    },
    async fetchActivities() {
      const res = await this.$store.dispatch("post-activity/find", {
        query: {
          tripId: this.$route.params.id,
          $sort: {
            createdAt: -1
          }
        }
      });
      this.activities = [];
      this.activities = res.data;
    },
    updateActivity(updatedActivity) {
      const index = this.activities.findIndex(
        (acc) => acc.uuid === updatedActivity.uuid
      );
      if (index !== -1) this.$set(this.activities, index, updatedActivity);
    },
    async fetchAndSortActivities(sortField) {
      const res = await this.$store.dispatch("post-activity/find", {
        query: {
          tripId: this.$route.params.id,
          $sort: {
            [sortField]: -1
          }
        }
      });
      this.activities = res.data;
    },
    async fetchAndSortByLikes() {
      const res = await this.$store.dispatch("post-activity/find", {
        query: {
          tripId: this.$route.params.id
        }
      });
      const activities = res.data;

      activities.sort((a, b) => {
        const likesA = a.post_likes.length;
        const likesB = b.post_likes.length;

        return likesB - likesA;
      });
      this.activities = activities;
    }
  },
  async beforeMount() {
    const itineraryRes = await this.$store.dispatch("itinerary/find", {
      query: {
        tripId: this.$route.params.id
      }
    });
    if (itineraryRes.data) this.itinerary = itineraryRes.data;

    this.fetchTrip();
    this.fetchActivities();
  }
};
</script>

<template>
  <div class="tw-mb-10">
    <activities-list />
  </div>
</template>

<script>
import ActivitiesList from "@/components/activities/ActivitiesList.vue";

export default {
  name: "ActivitiesView",
  components: {
    ActivitiesList
  },
  beforeMount() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "THINGS TO DO",
      bgColor: "#0036F5",
      fontColor: "#ffffff",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.$route.params.id
    });
    this.$store.commit("meta/SET_NAVIGATION_BUTTON", {
      button: false
    });
  },
  destroyed() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: false
    });
  }
};
</script>
